// @mui icons.
import AddCardIcon from '@mui/icons-material/AddCard';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssessmentIcon from '@mui/icons-material/Assessment';
import RecyclingIcon from '@mui/icons-material/Recycling';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import GroupIcon from '@mui/icons-material/Group';
import InventoryIcon from '@mui/icons-material/Inventory';
import WindPowerIcon from '@mui/icons-material/WindPower';
// components
// import SvgIconStyle from '../../../components/SvgIconStyle';
// type
import { NavSectionConfigProps } from '../../../components/nav-section/type';

// ----------------------------------------------------------------------

// const getIcon = (name: string) => (
//   <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
// );

const ICONS = {
  rewards: <AddCardIcon />,
  dashboard: <DashboardIcon />,
  missions: <Diversity3Icon />,
  storer: <WarehouseIcon />,
  recycling: <RecyclingIcon />,
  team: <GroupIcon />,
  inventory: <InventoryIcon />,
  esg: <WindPowerIcon />,
  report: <AssessmentIcon />,
};

export const getNavConfigForRoles = (
  role: undefined | null | string,
): Array<NavSectionConfigProps> => [
  {
    subheader: 'PRODUCER',
    items: [
      { title: 'Dashboard', path: '/dashboard', icon: ICONS.dashboard },
      { title: 'Missions', path: '/missions', icon: ICONS.missions },
      { title: 'Storers', path: '/storers', icon: ICONS.storer },
      { title: 'Recyclers', path: '/recyclers', icon: ICONS.recycling },
      { title: 'Report', path: '/report', icon: ICONS.report },
      { title: 'ESG', path: '/esg', icon: ICONS.esg },
      { title: 'Team', path: '/team', icon: ICONS.team },
    ],
  },
  {
    subheader: 'RECYCLER',
    items: [
      // { title: 'Scan Collector Code', path: '/dashboard', icon: ICONS.scan },
      { title: 'Dashboard', path: '/recycler/dashboard', icon: ICONS.dashboard },
      { title: 'Inventory', path: '/recycler/inventory', icon: ICONS.inventory },
      { title: 'Report', path: '/recycler/report', icon: ICONS.report },
    ],
  },
  {
    subheader: 'Waste Management Company',
    items: [
      // { title: 'Scan Collector Code', path: '/dashboard', icon: ICONS.scan },
      { title: 'Dashboard', path: '/waste_management/dashboard', icon: ICONS.dashboard },
      { title: 'Inventory', path: '/waste_management/inventory', icon: ICONS.inventory },
      { title: 'Report', path: '/waste_management/report', icon: ICONS.report },
    ],
  },
];
