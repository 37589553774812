// Lib
import axios from 'axios';
import { EnqueueSnackbar } from 'notistack';
import { CredentialResponse } from '@react-oauth/google';
// Utils
import { getAccessTokenCookieData, setAccessTokenCookieData } from '../utils/cookie';
import getHost from '../utils/getHost';
// Models
import BaseModel from '../@types/models/BaseModel';

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
};

export const googleAuthenticate = async (credentialResponse: CredentialResponse) => {
  const { data } = await axios.post(`${getHost()}/api/v1/auth/google`, credentialResponse, {
    headers: DEFAULT_HEADERS,
  });

  const { token, expiresInDays } = data.data;
  setAccessTokenCookieData(token, expiresInDays);
  return { data: data.data, jwtToken: token };
};

type RequestType = 'GET' | 'POST' | 'PUT';

const _sendRequest = async (
  requestType: RequestType,
  path: string,
  body: null | Record<string, any>,
  errorEnqueueSnackbar: null | EnqueueSnackbar,
  expectedModels: boolean,
): Promise<null | BaseModel | Array<BaseModel>> => {
  const baseUrl = getHost();
  try {
    const authToken = getAccessTokenCookieData();

    const url = `${baseUrl}/api/v1${path}`.trim();
    if (body) {
      _trimStringValues(body);
    }
    const config = {
      headers: Object.assign(
        DEFAULT_HEADERS,
        authToken && authToken !== ''
          ? {
              Authorization: 'Bearer ' + authToken,
            }
          : {},
      ),
    };
    let request;
    switch (requestType) {
      case 'GET':
        request = axios.get(url, config);
        break;
      case 'POST':
        request = axios.post(url, body, config);
        break;
      case 'PUT':
        request = axios.put(url, body, config);
        break;
    }
    if (!request) {
      console.error('Incorrect type', requestType);
    }
    const result = await request;
    // Return only 200 & model type result.
    if (result.status === 200 || result.status === 201) {
      if (expectedModels) {
        // TODO
        return result.data as Array<BaseModel>;
      } else {
        if (result.data.data.typename) {
          return result.data as BaseModel;
        } else {
          console.error('Not a model', result.data);
        }
      }
    } else {
      console.error(`Not a 200 OK, but ${result.status}`);
    }
  } catch (error) {
    console.error(error);
    if (errorEnqueueSnackbar) {
      errorEnqueueSnackbar(`${error.message}: ${error.response?.data?.error || 'n/a'}`, {
        variant: 'error',
        autoHideDuration: 10000,
      });
    }
  }
  return null;
};

export const getModel = async (
  path: string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | BaseModel> =>
  _sendRequest('GET', path, null, errorEnqueueSnackbar, false) as Promise<null | BaseModel>;

export const apiGetModelByType = async (
  modelId: string,
  modelType: string,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | BaseModel> => {
  const model = await getModel(`/admin/model/${modelType}/${modelId}`, errorEnqueueSnackbar);
  if (!model && errorEnqueueSnackbar) {
    errorEnqueueSnackbar(`Model ${modelType} :: ${modelId} is not available in DB`, {
      variant: 'error',
      autoHideDuration: 10000,
    });
  }
  return model ? (model as BaseModel) : null;
};

export const postModel = async (
  path: string,
  body: null | Record<string, any> = null,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | BaseModel> =>
  _sendRequest('POST', path, body, errorEnqueueSnackbar, false) as Promise<null | BaseModel>;

export const putModel = async (
  path: string,
  body: null | Record<string, any> = null,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | BaseModel> =>
  _sendRequest('PUT', path, body, errorEnqueueSnackbar, false) as Promise<null | BaseModel>;

export const getModels = async (
  path: string,
  body: null | Record<string, any> = null,
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | Array<BaseModel>> =>
  _sendRequest('GET', path, body, errorEnqueueSnackbar, true) as Promise<null | Array<BaseModel>>;

const _trimStringValues = (obj: Record<string, any>) => {
  // Check if the input is an object
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  // Iterate through the object's properties
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Check if the property value is a string
      if (typeof obj[key] === 'string') {
        // Trim the string value
        obj[key] = obj[key].trim();
      } else if (typeof obj[key] === 'object') {
        // If the property is an object, recursively call the function
        obj[key] = _trimStringValues(obj[key]);
      }
    }
  }

  return obj;
};
