import axios from 'axios';
// Lib
import { EnqueueSnackbar } from 'notistack';
// Models
import UserModel from '../@types/models/UserModel';
import { getModel } from './base';
import getHost from 'src/utils/getHost';

export const apiGetCurrentUser = async (
  errorEnqueueSnackbar: null | EnqueueSnackbar = null,
): Promise<null | UserModel> => {
  const model = await getModel('/user', errorEnqueueSnackbar);
  return model ? (model as UserModel) : null;
};

export const getUserById = async (id: string) => {
  const result = await axios.get(`${getHost()}/api/v1/users/${id}`);
  return result.data.data;
};
