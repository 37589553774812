import { useState, useEffect } from 'react';
// Libs
import { SnackbarProvider, useSnackbar } from 'notistack';
import { GoogleOAuthProvider, CredentialResponse } from '@react-oauth/google';
// @mui
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// theme
import ThemeProvider from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import ThemeColorPresets from './components/ThemeColorPresets';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import LoadingScreen from './components/LoadingScreen';
// Context
import UserContext from './contexts/UserContext';
// Models
import UserModel from './@types/models/UserModel';
// API
import { googleAuthenticate } from './api/base';
import { getUserById } from './api/user';
// Utils
import { getAccessTokenCookieData } from './utils/cookie';
// Routes
import Router from './routes';
import './global.css';
// ----------------------------------------------------------------------

const googleClientId = '887300661713-h3jh0bgd708r2dt9gc0josdsdnkku4ic.apps.googleusercontent.com';

// ----------------------------------------------------------------------

export default function App() {
  const [userModel, setUserModel] = useState<UserModel | any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const getUser = async (id: string) => {
    try {
      const user = await getUserById(id);
      if (user.id !== 'undefined') {
        setUserModel(user);
      } else {
        setUserModel(null);
      }
    } catch (err) {
      console.error('Failed to get user:', err);
      setUserModel(null);
    } finally {
      setLoading(false);
    }
  };

  const googleSignInSuccess = (credentialResponse: CredentialResponse): void => {
    googleAuthenticate(credentialResponse).then(async (res) => getUser(res.jwtToken));
  };

  useEffect(() => {
    const fetchUserData = async () => {
      // get the authToken from the browser
      const token: any = getAccessTokenCookieData();

      if (token) {
        // fetch from the api
        await getUser(token);
      } else {
        setLoading(false);
      }
    };

    fetchUserData().catch((err) => console.log(err));
  }, []);

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <RtlLayout>
          <MotionLazyContainer>
            <ProgressBarStyle />
            <Settings />
            <ScrollToTop />
            <SnackbarProvider autoHideDuration={5500} maxSnack={3}>
              <GoogleOAuthProvider key={userModel?.id} clientId={googleClientId}>
                <UserContext.Provider value={{ userModel, googleSignInSuccess }}>
                  {loading ? (
                    <Backdrop
                      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                      open={loading}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  ) : (
                    <Router userModel={userModel} />
                  )}
                </UserContext.Provider>
              </GoogleOAuthProvider>
            </SnackbarProvider>
          </MotionLazyContainer>
        </RtlLayout>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
