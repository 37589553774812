import React, { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// Layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// Components
import LoadingScreen from '../components/LoadingScreen';
// Models
import UserModel from '../@types/models/UserModel';

// ----------------------------------------------------------------------

type Props = {
  userModel?: UserModel | null | undefined;
};

function LoggedRouter(): React.ReactElement {
  return (
    <>
      {useRoutes([
        {
          path: '/',
          element: <Navigate to="/dashboard" replace />,
        },
        {
          path: '/',
          element: <DashboardLayout />,
          children: [
            // { element: <Navigate to="/dashboard" replace />, index: false },
            // { element: <Dashboard /> },
            { path: 'dashboard', element: <EnterpriseDashboard /> },
            { path: 'missions', element: <EnterpriseMissions /> },
            { path: 'missions/new', element: <EnterpriseNewMission /> },
            { path: 'mission/:missionId', element: <EnterpriseMission /> },
            { path: 'mission/:missionId/qrcodes', element: <MissionQRCodes /> },
            { path: 'storers', element: <EnterpriseStorers /> },
            { path: 'storer/:missionId', element: <EnterpriseStorer /> },
            { path: 'recyclers', element: <EnterpriseRecyclers /> },
            { path: 'report', element: <EnterpriseReport /> },
            { path: 'esg', element: <EnterpriseEsg /> },
            { path: 'team', element: <EnterpriseTeam /> },
          ],
        },
        {
          path: '/recycler',
          element: <DashboardLayout />,
          children: [
            { element: <Navigate to="/recycler/dashboard" replace />, index: true },
            { path: 'dashboard', element: <RecyclerDashboard /> },
            { path: 'report', element: <RecyclerReport /> },
            { path: 'inventory', element: <RecyclerInventory /> },
            { path: 'confirm/receipt', element: <RecyclerConfirmReceipt /> },
          ],
        },
        {
          path: '/waste_management',
          element: <DashboardLayout />,
          children: [
            { element: <Navigate to="/waste_management/dashboard" replace />, index: true },
            { path: 'dashboard', element: <WasteDashboard /> },
            { path: 'report', element: <WasteReport /> },
            { path: 'inventory', element: <WasteInventory /> },
            { path: 'inventory/confirm', element: <WasteConfirm /> },
            { path: 'confirm/receipt', element: <WasteConfirmReceipt /> },
          ],
        },
        {
          path: '*',
          element: <LogoOnlyLayout />,
          children: [
            { path: '404', element: <NotFound /> },
            { path: '*', element: <Navigate to="/404" replace /> },
          ],
        },
        { path: '*', element: <Navigate to="/404" replace /> },
      ])}
    </>
  );
}

// ----------------------------------------------------------------------

function NotLoggedRouter(): React.ReactElement {
  return (
    <>
      {useRoutes([
        {
          path: '/',
          element: <Login />,
        },
        { path: '*', element: <Navigate to="/" replace /> },
      ])}
    </>
  );
}

// ----------------------------------------------------------------------

export default function Router({ userModel }: Props): React.ReactElement {
  return Boolean(userModel?.isSigned) ? <LoggedRouter /> : <NotLoggedRouter />;
}

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

// Login
const Login = Loadable(lazy(() => import('../pages/Login')));
// Recycler
const RecyclerDashboard = Loadable(lazy(() => import('../pages/recycler/Dashboard')));
const RecyclerReport = Loadable(lazy(() => import('../pages/recycler/Report')));
const RecyclerInventory = Loadable(lazy(() => import('../pages/recycler/Inventory')));
const RecyclerConfirmReceipt = Loadable(lazy(() => import('../pages/recycler/ConfirmReceipt')));
// waste_management
const WasteDashboard = Loadable(lazy(() => import('../pages/wasteManagment/Dashboard')));
const WasteReport = Loadable(lazy(() => import('../pages/wasteManagment/Report')));
const WasteInventory = Loadable(lazy(() => import('../pages/wasteManagment/Inventory')));
const WasteConfirm = Loadable(lazy(() => import('../pages/wasteManagment/Confirm')));
const WasteConfirmReceipt = Loadable(lazy(() => import('../pages/wasteManagment/ConfirmReceipt')));
// Enterprise
const EnterpriseDashboard = Loadable(lazy(() => import('../pages/enterprise/Dashboard')));
const EnterpriseMissions = Loadable(lazy(() => import('../pages/enterprise/Missions')));
const EnterpriseMission = Loadable(lazy(() => import('../pages/enterprise/Mission')));
const MissionQRCodes = Loadable(lazy(() => import('../pages/enterprise/MissionQRCodes')));
const EnterpriseNewMission = Loadable(lazy(() => import('../pages/enterprise/NewMission')));
const EnterpriseStorers = Loadable(lazy(() => import('../pages/enterprise/Storers')));
const EnterpriseStorer = Loadable(lazy(() => import('../pages/enterprise/Storer')));
const EnterpriseRecyclers = Loadable(lazy(() => import('../pages/enterprise/Recyclers')));
const EnterpriseTeam = Loadable(lazy(() => import('../pages/enterprise/Team')));
const EnterpriseEsg = Loadable(lazy(() => import('../pages/enterprise/Esg')));
const EnterpriseReport = Loadable(lazy(() => import('../pages/enterprise/Report')));
// Enterprise
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
